<template>
    <footer class="footer">
        <div class="footer__info">
            <div>
                <Logo />
                <span class="text-100">
                    {{ $t('§footer.transforming_equipment_finance') }}
                </span>
            </div>
            <div>
                <span class="text-100">
                    {{ getCurrentYear }} {{ $t('§Corporate_info.company2_name') }}
                </span>
                <span class="text-100">
                    {{ $t('§footer.all_rights_reserved') }}
                </span>
                <span class="text-100">8115f055</span>
                <HelpLink />
            </div>
        </div>
        <div class="footer__icons">
            <div @click="openLinkedIn"><LinkedIn /></div>
            <div @click="goToTop"><ArrowUp /></div>
        </div>

        <div class="footer__waves">
            <RightDown class="footer__wave-right-down" />
            <RightUp class="footer__wave-right-up" />
            <LeftDown class="footer__wave-left-down" />
        </div>
    </footer>
</template>

<script>
import Logo from '../assets/svg/FooterLogo.vue'
import LinkedIn from '../assets/svg/LinkedIn.vue'
import ArrowUp from '../assets/svg/ArrowUp.vue'
import RightDown from '../assets/svg/FooterRightDown.vue'
import RightUp from '../assets/svg/FooterRightUp.vue'
import LeftDown from '../assets/svg/FooterLeftDown.vue'
import HelpLink from '@/components/HelpLink'

export default {
    name: 'Footer',

    components: {
        Logo,
        LinkedIn,
        ArrowUp,
        RightDown,
        RightUp,
        LeftDown,
        HelpLink,
    },

    computed: {
        getCurrentYear() {
            return new Date().getFullYear()
        },
    },

    methods: {
        goToTop() {
            window.scrollTo(0, 0)
        },

        openLinkedIn() {
            window.open('https://www.linkedin.com/company/linxfour', '_blank')
        },
    },
}
</script>

<style lang="scss">
.footer {
    width: 100%;
    max-width: 1880px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 24px 48px 12px;

    position: relative;

    &__info {
        display: flex;
        gap: 8rem;
        align-items: center;
        height: 42px;

        & > div:first-child {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        & > div:last-child {
            display: flex;
            gap: 24px;
            align-items: center;
        }
    }

    &__icons {
        display: flex;
        gap: 16px;

        & > div {
            position: relative;
            z-index: 1;
            cursor: pointer;
        }
    }

    &__waves {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;

        & .footer__wave-right-up {
            position: absolute;
            bottom: 0;
            right: 0;
        }

        & .footer__wave-left-down {
            position: absolute;
            bottom: 0;
            right: 300px;
        }

        & .footer__wave-right-down {
            position: absolute;
            bottom: 250px;
            right: 0;
        }
    }
}
</style>
