import Vue from 'vue'
import VueGtag from 'vue-gtag'
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import Hotjar from 'vue-hotjar'
import { ZiggyVue } from 'ziggy-js'
import 'modern-normalize'
import VueCurrencyInput from 'vue-currency-input'
import 'vue-slider-component/theme/antd.css'
import PrimeVue from '@/../../node_modules/primevue/config/index.js'
import Tooltip from 'primevue/tooltip'
import CountryFlag from 'vue-country-flag'

import { loadZiggyRoutes } from './helpers/api.js'
import router from './router'
import runBootstrap from './bootstrap'
import mixin from './helpers/mixins'
import { store } from './store'
import './assets/styles/theme.css'
import { initializeSentry } from './config/sentryConfig.js'
import { vueI18nConfig } from './config/vueI18nConfig.js'

import App from './App.vue'
import Login from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'
import ResetPasswordRequest from '@/views/Auth/ResetPasswordRequest.vue'
import SetNewPassword from '@/views/Auth/SetNewPassword.vue'
import InvalidInvite from '@/views/Auth/InvalidInvite.vue'

runBootstrap()
initializeSentry(Vue, router)
Vue.use(PrimeVue)
Vue.directive('tooltip', Tooltip)

Vue.use(mdiVue, {
    icons: mdijs,
})
window.store = store

const pluginOptions = {
    /* see config reference */
    globalOptions: {
        currency: 'EUR',
        locale: 'de',
        distractionFree: false,
        valueAsInteger: true,
        autoDecimalMode: false,
    },
}
Vue.use(VueCurrencyInput, pluginOptions)

Vue.config.productionTip = false

Vue.use(
    VueGtag,
    {
        config: { id: 'G-E5F9PZTPWB' },
    },
    router
)

Vue.use(Hotjar, {
    id: '3376486', // Hotjar Site ID
})

Vue.component('country-flag', CountryFlag)

const initVueApp = async () => {
    const ziggyRoutes = await loadZiggyRoutes()

    if (ziggyRoutes) {
        Vue.use(ZiggyVue, ziggyRoutes)

        const i18n = vueI18nConfig(Vue)

        new Vue({
            el: '#vue-app',
            store,
            i18n,
            router,
            components: {
                app: App,
                login: Login,
                register: Register,
                'reset-password-request': ResetPasswordRequest,
                'set-new-password': SetNewPassword,
                'invalid-invite': InvalidInvite,
            },
        })
    } else {
        console.error('Failed to load Ziggy routes. Vue app initialization aborted.')
    }
}

if (document.readyState === 'complete' || document.readyState !== 'loading') {
    initVueApp()
} else {
    document.addEventListener('DOMContentLoaded', initVueApp)
}
